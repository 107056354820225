module.exports = {
  name: 'Hybrid Training',
  logo: 'https://img.genflow.com/hybrid/checkout/logo.png',
  vendorUUID: 'b4526bce-c554-490a-ba4a-8e7054ddd149',
  metaData: {
      title: 'Hybrid Training',
      description: 'Willkommen bei Hybrid Training.',
      author: 'GENFLOW',
      url: 'https://hybridtraining.de/',
      image: 'https://img.genflow.com/hybrid/checkout/logo.png',
  },
  theme: {
      colors: {
          primary: '#ce1b1b',
          text: '#697386',
          ctaText: '#ffffff',
      },
  },
  analytics: {
      gtag: '',
      facebookPixel: '',
      tagManager: '',
      tapfiliate: '',
      // segment: '', - Stored in env
  },
  links: {
      terms: 'https://hybridtraining.de/terms-conditions',
      privacy: 'https://hybridtraining.de/privacy-policy',
      support: 'https://support.hybridtraining.de/',
      genflow: 'https://genflow.com',
      ios: 'https://apps.apple.com/gb/app/hybrid-training/id1550616002',
      android: 'https://play.google.com/store/apps/details?id=de.idonos.hybrid_training&hl=en&gl=US',
  },
  forms: {
      errors: {
          existingUser: 'Dieser Benutzer existiert bereits, bitte melde dich an.',
          invalidPassword: 'Das Passwort muss 6 oder mehr Zeichen enthalten.',
          unknownError: 'Die Registrierung konnte nicht verarbeitet werden.',
          userNotFound: 'Falsche Email/Passwort-Kombination',
      },
  },
  pages: {
      register: {
          title: 'Anmelden für',
          ctaText: 'Registrieren',
          ctaLoadingText: 'Registrieren',
      },
      login: {
          title: 'Einloggen in',
          ctaText: 'Login',
          ctaLoadingText: 'Einloggen',

      },
  },
  products: [
      {
          title: '1 Month',
          product: '1month',
          label: null,
      }, 
      {
          title: '3 Months',
          product: '3month',
      },
      {
        title: '12 Months',
        product: '12month',
    },
  ],
};
