const GROW = require('./brands/grow');
const OWNU = require('./brands/ownu');
const BURN = require('./brands/burn');
const LEAN = require('./brands/lean');
const STEFANIE = require('./brands/stefanie');
const MORSIA = require('./brands/morsia');
const SLAY = require('./brands/slay');
const LEANA = require('./brands/leana');
const NONA = require('./brands/nona');
const STRIVE = require('./brands/strive');
const NOBS = require('./brands/nobs');
const BEYOND = require('./brands/beyond');
const SHREDDY = require('./brands/shreddy');
const THRST = require('./brands/thrst');
const DANI = require('./brands/dani');
const ASHTON = require('./brands/ashton');
const HARDBODY = require('./brands/hardbody');
const RISE = require('./brands/rise');
const HYBRID = require('./brands/hybrid');
const MANNY = require('./brands/manny');

const vendorUuid = process.env.GATSBY_VENDOR_UUID;

const BRANDS = {
    '46bfa286-6c01-4898-843a-5ce8d7cb1398': GROW,
    '8ecc5861-cebf-47db-a78f-f876b8ab453c': OWNU,
    '9754e2ba-93f6-4ab3-b32f-78d5f776815d': BURN,
    'a9ee763f-e6db-43cb-81a4-26e4bd0a573e': LEAN,
    'bf1a0653-a95d-4e4a-9b0f-3950b3a63224': STEFANIE,
    '502903d1-74a2-4b85-b6cd-a7d95ca00050': MORSIA,
    'f514e99d-5e52-46f2-a021-315eb06d7109': SLAY,
    '001dcbaf-4985-411f-a0d5-f2c4a83a6d00': LEANA,
    'f3f7c717-e336-4874-9f05-c83c337c56a3': NONA,
    '015fb9a3-08dc-4f60-b648-a8836aa572ad': STRIVE,
    'b4456492-3a93-11e9-bfcd-0ac0203a1ad8': NOBS,
    'c8ab735e-6ae3-40ee-bdf3-42e39abb6fb7': BEYOND,
    '8caa4678-4871-4e9e-9f48-3847e2271454': SHREDDY,
    '29cede06-952e-4d7e-9184-9f18490b9a4e': THRST,
    'a56da3ab-8781-41a9-923e-20daef59ea88': DANI,
    '46fecfd5-76bf-4a46-8523-8425d660359c': ASHTON,
    'c41f82a3-8a85-4cfe-be1c-596b438cc0a3': HARDBODY,
    '90947471-502e-4a10-8555-39ec04405853': RISE,
    'b4526bce-c554-490a-ba4a-8e7054ddd149': HYBRID,
    '7c16e419-dead-459e-8920-7ed31020b8ea': MANNY,
};

module.exports = BRANDS[vendorUuid];
